<div class="app-home" *ngIf="login">
    <div class="sub-panel">
        <h4 style="margin-left: 2px;">
            {{'Home.title' | translate}}
            <button id="searchPanelButton" class="clear-button show-hide-panel">
                <em class="material-icons expand_less">expand_less</em>
                <em class="material-icons expand_more">expand_more</em>
            </button>
        </h4>
        <div class="sub-panel-body">
            <div class="row">
                <div class="table-responsive">
                    <table aria-label="This table will have all parent names of screens" class="navigation-table col-lg-9 col-md-9">
                        <tbody>
                            <tr>
                                <th>Select an option from the list below</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let item of menu">
                                <td><a [routerLink]="item.link">{{item.title}}</a></td>
                                <td>{{item.description}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-panel">
        <h4>
            {{'JobMonitor.title' | translate}}
            <button id="Job_Monitor_Button" class="clear-button show-hide-panel">
                <em class="material-icons expand_less">expand_less</em>
                <em class="material-icons expand_more">expand_more</em>
            </button>
        </h4>
        <div class="sub-panel-body">
            <div class="row row1" *ngIf="jobStatus.length>0">
                <span class="row col-lg-5" *ngFor="let item of jobStatus;index as i" [ngClass]="{'even': i%2===1}">
                    <div
                        [ngClass]="{'job_success': item.status=='COMPLETED' ,'job_failure': item.status=='FAILED','waiting': item.status=='INPROGRESS','panel':true,'panel-default':true,'col-lg-10':true,'col-md-10':true}">
                        <div [ngClass]="">
                            <div class="panel-body">
                                <div class="heading">
                                    <button class="btn btn-link"
                                        style="border: none; background: none;margin: 0px; padding: 0px;"
                                        (click)="showValidationDetails(item,item.validationSetRunId)">
                                        <h6>Validation {{item.validationSetRunId}}</h6>
                                    </button>
                                </div>
                                <div class="grid-container">
                                    <div [ngClass]="{'grey': item.initialsColor==3,'orange': item.initialsColor==2, 'lg': item.initialsColor==1 , 'lb': item.initialsColor==0}"
                                        id="initials" class="item1">{{item.initials}}</div>
                                    <div id="user" class="item2">{{item.createdById}}</div>
                                    <div class="time item3">
                                        {{item.startTime | date:'MM/dd/yyyy hh:mm:ss a'}}
                                        <span *ngIf="item.status!='INPROGRESS' && item.runLength>=0 "> for
                                            {{item.runLength}} sec</span>
            </div>
        </div>
    </div>
</div>
</div>
</span>
</div>
<div class="row" *ngIf="jobStatus.length==0">
    <span *ngIf="!loading" style="color: #D0021B;margin-left: 20px;">Error loading the Validation Job
                    Status</span>
    <span *ngIf="loading" style="color: #004A96;margin-left: 20px;">Loading Validation Job Status</span>
</div>
</div>
</div>
<div class="sub-panel">
    <h4>
        {{'Resources.title' | translate}}
        <button id="Resources_Button" class="clear-button show-hide-panel">
                <em class="material-icons expand_less">expand_less</em>
                <em class="material-icons expand_more">expand_more</em>
            </button>
    </h4>
    <div class="sub-panel-body">
        <div class="row">
            <div style="margin-left: 5px;width: 12%;">
                <a href="../../assets/EBIZ cloud user manual.pdf" download target="_blank" rel="noopener noreferrer"><span class="material-icons"
                            style="color: #D0021B;">
                            picture_as_pdf
                        </span></a>
                <span class="pdf">eBiz User <div class="pdfDesc">Guide</div></span>
            </div>
            <div style="width: 12%;">
                <button style="border: none; background: none; margin: 0px; padding: 0px;" (click)="downloadExcel('sales')"><em class="fa fa-file-excel-o excel-icon"></em></button>
                <span class="excel">Sales <div class="excelDesc">Template</div></span>
            </div>
            <div style="width: 14%;">
                <button style="border: none; background: none; margin: 0px; padding: 0px;" (click)="downloadExcel('inventory')"><em class="fa fa-file-excel-o excel-icon"></em></button>
                <span class="excel">Inventory <div class="excelDesc">Template</div></span>
            </div>
            <div style="width: 18%;">
                <button style="border: none; background: none; margin: 0px; padding: 0px;" (click)="downloadExcel('end_inventory')"><em class="fa fa-file-excel-o excel-icon"></em></button>
                <span class="excel">Ending Inventory <div class="excelDesc">Template</div></span>
            </div>
            <div style="width: 22%;">
                <button style="border: none; background: none; margin: 0px; padding: 0px;" (click)="downloadExcel('demographics_sales')"><em
                            class="fa fa-file-excel-o excel-icon"></em></button>
                <span class="excel">Demographic Overrides <div class="excelDesc">Template</div></span>
            </div>
            <div style="width: 18%;">
                <a href="https://app.powerbi.com/browse/shared" target="_blank" rel="noopener noreferrer"> <img alt="Power-BI" src="./assets/images/Power-BI.svg" width="25px" height="25px"></a>
                <span class="excel">Power BI<div class="tableau">Reports</div></span>
            </div>
        </div>
    </div>
</div>
</div>