import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../common/component';
import { AppState } from '../app.state';
import { IdentityService } from '../common/service/IdentityService';
import { SalesValidationService } from '../common/service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: false
})
export class HomeComponent extends BaseComponent implements OnInit {
  login: boolean;
  menu: [];
  table1 = false;
  jobStatus = [];
  loading = true;

  constructor(public service: IdentityService, public translate: TranslateService, public state: AppState, public service1: SalesValidationService, public router: Router) {
    super();
    this.state.changeBreadcrumbs([{ name: 'eBiz', link: '' }]);
  }

  ngOnInit(): void {
    this.translate.get('menu').subscribe(data => {
      this.menu = data;
    });

    this.login = this.service.loggedIn;

    if (!this.login) {
      let interval = setInterval(() => {
        this.login = this.service.loggedIn;
        if (this.login && this.state.get('access_token') != null) {
          this.getValidationSet();
          clearInterval(interval);
        }
      }, 1000);
    } else {
      let interval2 = setInterval(() => {
        if (this.state.get('access_token') != null) {
          this.getValidationSet();
          clearInterval(interval2);
        }
      }, 1000);
    }
  }

  getValidationSet() {
    let colors = [0];
    let temp = 0;
    let lookup = { "EBIZ": '3' };
    this.service1.getValidationSets().subscribe({
      next: data => {
        this.jobStatus = data['data'];
        for (let i of this.jobStatus) {
          i['runLength'] = (new Date(i['endTime']).getTime() - new Date(i['startTime']).getTime()) / 1000;
          if (lookup[i['createdById']] == undefined) {
            if (i['createdById'] != 'EBIZ') {
              lookup[i['createdById']] = colors[temp];
              if (temp == 2) {
                temp = 0;
              } else {
                colors.push(colors[temp] + 1);
                temp += 1;
              }
            }
          }
          i['initialsColor'] = lookup[i['createdById']];
          if (i['createdById'] == 'EBIZ') {
            i['initials'] = 'SY';
          } else {
            let s = '';
            for (let j = i['createdById'].length - 1; j >= 0; j--) {
              if (i['createdById'].charCodeAt(j) >= 65 && i['createdById'].charCodeAt(j) <= 90) {
                s += i['createdById'][j];
                break;
              }
            }
            s += i['createdById'][0];
            i['initials'] = s;
          }
        }
      },
      error: error => {
        this.loading = false;
      }
    });
  }

  showValidationDetails(cred, id) {
    this.state.set('Credentials', cred);
    this.router.navigate(['current', 'validationSet', `${id}`]);
  }

  downloadExcel(option: string) {
    let header: string[] = [];

    // Define headers based on option
    if (option === 'sales') {
      header = ['DIVISION_CODE', 'REPORTING_LOCATION_ID', 'WAREHOUSE_ID', 'INVOICE_DATE',
        'INVOICE_NUMBER', 'ARTICLE_NO', 'QUANTITY', 'UOM', 'SHIP_TO_CUSTOMER_TYPE',
        'SHIP_TO_CUSTOMER_ID', 'SHIP_NAME', 'SHIP_ADDR', 'SHIP_ADDR2', 'SHIP_CITY',
        'SHIP_STATE', 'SHIP_ZIP', 'BILL_TO_CUSTOMER_TYPE', 'BILL_TO_CUSTOMER_ID',
        'BILL_NAME', 'BILL_ADDR', 'BILL_ADDR2', 'BILL_CITY', 'BILL_STATE', 'BILL_ZIP',
        'POS_KEY', 'REPLOC_TYPE', 'DISTRIBUTOR_SALES_REP', 'ORDER_DATE', 'SHIP_TO_BASF_ID'];
    } else if (option === 'inventory') {
      header = ['DIVISION_CODE', 'REPORTING_LOCATION_ID', 'WAREHOUSE_ID', 'INVENTORY_REPORT_DATE',
        'ARTICLE_NO', 'QUANTITY_ON_HAND', 'UNIT_OF_MEASURE_TYPE'];
    } else if (option === 'end_inventory') {
      header = ['REPORTING_LOCATION_ID', 'WAREHOUSE_ID', 'ARTICLE_NO', 'INVENTORY_QTY',
        'UNIT_OF_MEASURE_TYPE', 'SEASON_ID', 'BUSINESS_SEGMENT_CD'];
    } else if (option === 'demographics_sales') {
      header = ['Rec ID', 'Rep Loc ID', 'Rep Loc Type', 'Trans Date', 'Prod Trans Type',
        'Invoice No', 'Invoice Qual', 'Article No', 'Qty', 'Qty Deb Cred Type', 'UOM',
        'Ship To Cust ID', 'Ship To Cust Type', 'Ship To Name', 'Ship To Addr',
        'Ship To Addr 2', 'Ship To City', 'Ship To State', 'Ship To Zip', 'Ship To BASF ID',
        'Bill To Cust ID', 'Bill To Name', 'Bill To Addr', 'Bill To Addr 2', 'Bill To City',
        'Bill To State', 'Bill To Zip', 'Bill To BASF ID', 'WH ID', 'WH Name', 'WH Addr',
        'WH City', 'WH State', 'WH Zip', 'Distributor Sales Rep', 'Order Date'];
    }

    // Create worksheet with header
    const ws = XLSX.utils.aoa_to_sheet([header]);

    // Set uniform column width of 30 for all columns
    const colWidth = 30;
    ws['!cols'] = Array(36).fill({ width: colWidth });

    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, option);

    // Generate buffer and create blob
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array'
    });

    // Create blob and save file
    const blob = new Blob([wbout], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, `${option}.xlsx`);
  }

}
