import { Injectable } from '@angular/core';
import { URL } from './URL';
import { Service } from './service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../../app.state';

@Injectable()
export class InventoryCurrentService extends Service {

  constructor(public http: HttpClient, public state: AppState) {
    super(http, state);
  }

  getCurrentInventory(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current?`, { 'filters': `offset=${offset}&&limit=${limit}${filter}` });
  }

  getErrors(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Error?`, { 'filters': `offset=${offset}&&limit=${limit}&&${filter}&&deleteFlag=N` });
  }

  deleteRecords(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current/delete?userId=${this.state.get('userId')}`, { recordIds: recIds.join(',') });
  }

  undeleteRecords(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current/undelete?userId=${this.state.get('userId')}`, { recordIds: recIds.join(',') });
  }

  getCount(filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/Count?`, { 'filters': `${filter}` });
  }

  validate(filters, recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/validate?` + `${filters.join('&')}&inProgressFlag=N`, { 'recordId': `${recIds.join(',')}`, 'userId': `${this.state.get('userId')}` });
  }

  validate1(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/validate?` + recIds + `&inProgressFlag=N`, { 'userId': `${this.state.get('userId')}` });
  }

  giveValidationStatus(execRequestId) {
    return this.get(URL.BASE_URL + `/inventory/JobStatus?validationSetRunId=${execRequestId}`);
  }

  save(sales): Observable<any> {
    let userId = this.state.get('userId');
    if (!userId) {
      userId = 'SYSTEM';
    }
    return this.put(URL.BASE_URL + `/inventory/Current?userId=${userId}`, sales);
  }

  getTransReceiptDate(divCode): Observable<any> {
    return this.get(URL.BASE_URL + `/inventory/transReceiptDate?divisionCode=${divCode}`);
  }

  getTransSetControl(transDate, divisionCode): Observable<any> {
    return this.get(URL.BASE_URL + `/inventory/inventorySummary?transactionReceiptDate=${transDate}&divisionCode=${divisionCode}`);
  }

  getReportingLocation(transDate, divisionCode): Observable<any> {
    return this.get(URL.BASE_URL + `/inventory/inventorySummary/ReportingLocation?transactionReceiptDate=${transDate}&divisionCode=${divisionCode}`);
  }

  getWarehouse(transDate, divisionCode): Observable<any> {
    return this.get(URL.BASE_URL + `/inventory/inventorySummary/Warehouse?transRecDate=${transDate}&divisionCode=${divisionCode}`);
  }

  overrideAllErrors(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/override?userId=${this.state.get('userId')}`, { 'recordIds': recIds.join(','), 'override': [404, 407, 408, 426, 434, 435, 999] });
  }

  overrideError(recIds, activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/override?userId=${this.state.get('userId')}`, { 'recordIds': recIds.join(','), 'override': [activityCode] });
  }

  overrideErrorforbatch(recIds, filtr, activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/current/override1?userId=${this.state.get('userId')}&${recIds}&${filtr}&activityCodeInventory=${activityCode}&overridenFlag=N`, { 'recordIds': '', 'override': [activityCode] });
  }

  getRefresh(source): Observable<any> {
    return this.get(URL.BASE_URL + `/inventory/Current/lastrefresh?source=${source}&userId=${this.state.get('userId')}`);
  }

  triggerRefresh(source): Observable<any> {
    return this.get(URL.BASE_URL + `/inventory/Current/trigger?userId=${this.state.get('userId')}&source=${source}`);
  }

  overrideErrorForAllRecords(filter, activityCode): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/override?userId=${this.state.get('userId')}&${filter}`, { 'recordIds': '', 'override': [activityCode] });
  }

  getvalidRecords(filters, recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Count/InProgress?` + `${filters.join('&')}`, { 'recordId': `${recIds.join(',')}`, 'userId': `${this.state.get('userId')}` });
  }

  getvalidRecords1(recIds): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Count/InProgress?` + recIds, { 'userId': `${this.state.get('userId')}` });
  }

  getCurrentSalesDownloads(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Current/Download?`, { 'filters': `offset=${offset}&&limit=${limit}${filter}` });
  }
  getErrorsDownloads(limit, offset, filter): Observable<any> {
    return this.post(URL.BASE_URL + `/inventory/Error/Download?`, { 'filters': `offset=${offset}&&limit=${limit}&&${filter}&&deleteFlag=N` });
  }

}
