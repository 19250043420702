import { Injectable } from '@angular/core';
import { URL } from './URL';
import { Service } from './service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppState } from '@app/app.state';

@Injectable()
export class IdentityService extends Service {
  loggedIn = false;
  ebiz = false;
  ebill = false;

  constructor(public http: HttpClient, public state: AppState) {
    super(http, state);
  }

  getAccessToken(code: string): Observable<any> {
    const body = { code: code, redirectUri: environment.OAUTH_REDIRECT_URI, grantType: 'authorization_code' };
    return this.post(URL.IDENTITY_BASE_URL, body, { 'Content-Type': 'application/json' });
  }

  // To get new access token using refresh token
  getNewAccessToken(refresh_token: string): Observable<any> {
    const body = { refreshToken: refresh_token, redirectUri: environment.OAUTH_REDIRECT_URI, grantType: 'refresh_token', scope: environment.SCOPE };
    return this.post(URL.IDENTITY_BASE_URL, body);
  }

  logIn(loggedIn) {
    this.loggedIn = loggedIn;
  }

  eBiz() {
    this.ebiz = true;
  }

  eBill() {
    this.ebill = true;
  }

  eBizAuthhorized() {
    return this.ebiz;
  }

  eBillAuthhorized() {
    return this.ebill;
  }

}